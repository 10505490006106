import React from 'react';

const SidebarFooter = () => {
	return (
		<>
			<div className="app-sidebar--footer">
				<ul>
					<li></li>
				</ul>
			</div>
		</>
	);
};

export default SidebarFooter;
