const LocationTable = {
	pagination: {
		nextTooltip: 'Siguiente Página',
		lastTooltip: 'Última Página',
		previousTooltip: 'Página Anterior',
		firstTooltip: 'Primera Página',
		labelRowsSelect: 'Filas',
		labelDisplayedRows: '{from}-{to} de {count}',
	},
	toolbar: {
		searchTooltip: 'Buscar',
		searchPlaceholder: 'Buscar',
		nRowsSelected: '{0} filas(s) seleccionadas',
	},
	header: {
		actions: 'Acciones',
	},
	body: {
		addTooltip: 'Agregar',
		emptyDataSourceMessage: 'No hay registros que mostrar',
		filterRow: {
			filterTooltip: 'Filtrar',
		},
		editTooltip: 'Editar',
		deleteTooltip: 'Eliminar',
		editRow: {
			deleteText:
				'¿Esta seguro que desea eliminar este proceso?, este cambio no se puede deshacer',
		},
	},
};

export { LocationTable };
